import {Checkbox} from 'antd'
import {useGetMediaQuery} from 'app/api/services/mediaApiSlice'
import Pagination from 'components/common/DataTable/components/Pagination'
import ProgressIndicator from 'components/common/ProgressIndicator/ProgressIndicator'
import {SERVICE_RESPONSE_STATUS} from 'enum/service.enum'
import {RenderIf} from 'helpers'
import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import './MediaList.scss'
import clsx from 'clsx'

type Props = {
  handleSubmit: (data: any) => void
  isMulti: boolean
}

const MediaList: FC<Props> = ({isMulti, handleSubmit}) => {
  const intl = useIntl()
  const [page, setPage] = useState<number>(1)
  const {data: fetchedData, isFetching, isLoading} = useGetMediaQuery(page)
  const [selectedImages, setSelectedImages] = useState<any[]>([])

  const pagination = useMemo(() => {
    if (fetchedData && fetchedData.status === SERVICE_RESPONSE_STATUS.SUCCESS) {
      return fetchedData.result.pagination
    }
    return []
  }, [fetchedData])

  const list = useMemo(() => {
    if (fetchedData && fetchedData.status === SERVICE_RESPONSE_STATUS.SUCCESS) {
      return fetchedData.result.data
    }
    return []
  }, [fetchedData])

  const makeConversion = (data: any) => {
    return {
      large: data.large?.url,
      medium: data.medium?.url,
      original: data.original?.url,
      thumb: data.thumb?.url,
    }
  }

  return (
    <>
      <RenderIf isTrue={isFetching || isLoading}>
        <ProgressIndicator />
      </RenderIf>
      <RenderIf isTrue={!isFetching && !isLoading}>
        <div className='mh-650px mt-5' style={{overflowY: 'auto', overflowX: 'hidden'}}>
          <div className='row g-0 justify-content-center w-100 px-3' style={{gap: 10}}>
            {list.map((data: any) => {
              const isChecked = selectedImages.some((item: any) => item.id === data.id)
              return (
                <div
                  className={clsx(
                    'col-md-1 border py-2 px-3  overflow-hidden rounded-2',
                    !isChecked && 'media-card'
                  )}
                  style={{backgroundColor: isChecked ? '#CFE2FF' : '#F7F7FA'}}
                  key={data.id}
                >
                  <div className='d-flex gap-2 align-items-center'>
                    <Checkbox
                      id={data.id}
                      checked={isChecked}
                      onChange={(e) => {
                        if (isMulti) {
                          if (e.target.checked) {
                            setSelectedImages((prev) => [
                              ...prev,
                              {id: data.id, conversions: makeConversion(data.conversions)},
                            ])
                          } else {
                            const filtered = selectedImages
                              .filter((item: any) => item.id !== data.id)
                              .map((item: any) => ({
                                id: item.id,
                                conversions: makeConversion(data.conversions),
                              }))
                            setSelectedImages(filtered)
                          }
                        } else {
                          if (e.target.checked) {
                            setSelectedImages([
                              {id: data.id, conversions: makeConversion(data.conversions)},
                            ])
                          } else {
                            setSelectedImages([])
                          }
                        }
                      }}
                    />

                    <div className='d-flex align-items-center justify-content-end gap-2'>
                      {data.type === 'image' ? (
                        <i className='fa-solid fa-image text-secondary'></i>
                      ) : (
                        <i className='fa-solid fa-video text-secondary'></i>
                      )}
                      <label htmlFor={data.id} className='cursor-pointer'>
                        <strong className='text-truncate'>{data.name}</strong>
                      </label>
                    </div>
                  </div>
                  <div
                    className='h-100 cursor-pointer'
                    onClick={() => {
                      setSelectedImages([
                        {id: data.id, conversions: makeConversion(data.conversions)},
                      ])
                    }}
                  >
                    <img
                      src={data.conversions.medium.url}
                      alt={data.conversions.medium.path}
                      className='w-100'
                    />
                  </div>
                </div>
              )
            })}
          </div>

          <div className='d-flex justify-content-center py-5 '>
            <Pagination
              className='pagination-bar'
              currentPage={pagination?.current_page}
              totalCount={pagination?.total}
              pageSize={pagination?.per_page}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        </div>
      </RenderIf>
      <div className='d-flex justify-content-end'>
        <button
          type='button'
          data-testid='submit-file-upload'
          className='m-5 text-center btn btn-primary'
          onClick={() => {
            handleSubmit(selectedImages)
            setSelectedImages([])
          }}
        >
          {intl.formatMessage({id: 'DONE'})}
        </button>
      </div>
    </>
  )
}

export default MediaList
