// @ts-nocheck
import {useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import Header from './Header'
import MediaList from './MediaList'
import axios from 'axios'
import FileUpload from 'components/FileUpload/FileUpload'
import {selectCurrentToken} from 'features/auth/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {onRemoveSelected, onSelect, selectedMedias} from './core/slice'
import {SERVICE_RESPONSE_STATUS} from 'enum/service.enum'
import {TMediaData} from 'models'
import './MediaModal.scss'

type Props = {
  show: boolean
  handleClose: () => void
  handleSubmit: (data: TMediaData) => void
  isMulti: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const MediaModal = ({show, handleClose, handleSubmit, isMulti}: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const token = useSelector(selectCurrentToken)
  const selectedMediasList = useSelector(selectedMedias)

  const [selectedImages, setSelectedImages] = useState([])
  const [selectedImage, setSelectedImage] = useState({})

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const [uploadedFilesData, setUploadedFilesData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleOnUploadFiles = (files) => {
    if (!files) return

    const uFiles = Object.entries(files)
    uFiles.forEach((uFile) => {
      const file = uFile[1]
      const type = file.type.includes('gif') ? 'file' : 'image'

      const fd = new FormData()
      fd.append('file', file)
      fd.append('type', type)
      setLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/panel/media/public`, fd, {
          headers: headers,
        })
        .then((res) => {
          const {data} = res
          const {status, result} = data
          if (status === SERVICE_RESPONSE_STATUS.SUCCESS) {
            let id
            let conversions

            setUploadedFilesData((prev) => [...prev, ...Object.values(result)])

            if (result.url && result.id) {
              id = result.id
              conversions = {
                large: result.url,
                medium: result.url,
                original: result.url,
                thumb: result.url,
              }
            } else {
              const selectedData = result[0]
              id = selectedData.id
              conversions = {
                large: selectedData.conversions.large.url,
                medium: selectedData.conversions.medium.url,
                original: selectedData.conversions.original.url,
                thumb: selectedData.conversions.thumb.url,
              }
            }

            dispatch(onSelect({id, conversions}))

            if (isMulti) {
              const tmpSelectedImages = [...selectedImages]
              tmpSelectedImages.push({id, conversions})
              setSelectedImages(tmpSelectedImages)
            } else {
              setSelectedImage({id, conversions})
            }
          }
        })
        .catch(() => {
          toast.error(intl.formatMessage({id: 'FAILED_TO_UPLOAD'}))
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const handleOnRemoveFile = (id) => {
    const uploadedFiles = [...uploadedFilesData]
    const filteredFiles = uploadedFiles.filter((file) => file.id !== id)
    setUploadedFilesData(filteredFiles)
    const indexToRemove = selectedMediasList.findIndex((file) => file.id === id)
    if (indexToRemove !== -1) {
      dispatch(onRemoveSelected(indexToRemove))
    }
  }

  return createPortal(
    <Modal
      id='media-modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered media-modal-dialog'
      show={show}
      backdrop
    >
      <Header
        handleClose={() => {
          handleClose()
        }}
        title='Select from Library'
      />
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 px-8'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#all_media_items'>
            {intl.formatMessage({id: 'ALL_MEDIA_ITEMS'})}
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#update_new_media'>
            {intl.formatMessage({id: 'UPLOAD_NEW_MEDIA'})}
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='h-100 tab-pane fade show active' id='all_media_items' role='tabpanel'>
          <MediaList handleSubmit={handleSubmit} isMulti={isMulti} />
        </div>
        <div className='tab-pane fade' id='update_new_media' role='tabpanel'>
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <FileUpload
              onUploadFiles={handleOnUploadFiles}
              uploadedFiles={uploadedFilesData}
              onRemoveFile={handleOnRemoveFile}
              loading={loading}
              error={false}
              dragDropMessage='Drag files here'
              fileSelectMessage='Or choose from a folder'
              isMulti={isMulti}
              onClose={() => {
                handleClose()
              }}
            />
            {(selectedImages && selectedImages.length > 0) ||
              (selectedImage.id && (
                <div className='w-100 d-flex justify-content-end'>
                  <button
                    type='button'
                    className='m-5 text-center btn btn-primary'
                    onClick={() => {
                      if (isMulti) {
                        handleSubmit(selectedImages)
                        setSelectedImages([])
                      } else {
                        handleSubmit(selectedImage)
                        setSelectedImages({})
                      }
                    }}
                  >
                    {intl.formatMessage({id: 'DONE'})}
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export default MediaModal
