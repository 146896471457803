// @ts-nocheck
import {useMemo, useRef, useState, useEffect} from 'react'
import {generateRandomId} from 'utils/string'
import FileUploadItem from './FileUploadItem'
import useDragAndDrop from 'hooks/useDragAndDrop'
import './FileUpload.scss'
import clsx from 'clsx'

const FileUpload = ({
  id,
  error,
  dragDropMessage,
  fileSelectMessage,
  onUploadFiles,
  uploadedFiles,
  onRemoveFile,
  isMulti,
  loading,
  onClose,
}) => {
  const fileUploadRef = useRef()

  const inputId = useMemo(() => id || generateRandomId(), [id])

  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop()

  const onDropHandler = (e) => {
    e.preventDefault()
    setDragOver(false)
    const filesObj = {}
    Object.values(e.dataTransfer.files).forEach((item) => {
      const {name} = item
      filesObj[name] = item
    })
    onUploadFiles(filesObj)
  }

  const onSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const filesObj = {}
    Object.values(e.target.files).forEach((item) => {
      const {name} = item
      filesObj[name] = item
    })
    onUploadFiles(filesObj)
  }

  return (
    <div className={clsx('file-upload position-relative', error && 'error')}>
      <input
        ref={fileUploadRef}
        type='file'
        id={inputId}
        multiple={isMulti}
        className='input-file'
        onChange={onSelectHandler}
        onClick={(e) => {
          e.target.value = null
        }}
      />

      {uploadedFiles.length > 0 && (
        <div className='upload-box'>
          <ul className='row g-4 mt-2' style={{gap: 10}}>
            {uploadedFiles.map((file, fileIndex) => (
              <div className='col-md-1  border border-1 rounded-2 px-3 py-2'>
                <FileUploadItem key={file.name} {...file} onRemoveFile={onRemoveFile} />
              </div>
            ))}
          </ul>
        </div>
      )}

      <div
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDropHandler}
        className={`input-file-box ps-10 ${loading ? 'cursor-not-allowed' : 'cursor-pointer'} ${
          dragOver && 'drag-over'
        }`}
        onClick={() => (loading ? undefined : fileUploadRef.current.click())}
      >
        <i className='fa-solid fa-cube fs-1 me-10' />
        <div>
          <p className='drag-drop-message'>{dragDropMessage}</p>
          {loading ? (
            <p className={'select-file-message'}>Yükleniyor...</p>
          ) : (
            <input type='button' value={fileSelectMessage} className={'select-file-message'} />
          )}
        </div>
      </div>
      {uploadedFiles.length ? (
        <button className='btn btn-primary mt-2 position-absolute add-button' onClick={onClose}>
          <span>Yükle</span>
        </button>
      ) : null}
    </div>
  )
}

export default FileUpload
