import {createSlice} from '@reduxjs/toolkit'

interface IMedia {
  selected: any
  showMediaModal: boolean
}

interface IMediaObject {
  media: IMedia
}

const initialState: IMedia = {
  showMediaModal: false,
  selected: [],
}

const mediaSlice = createSlice({
  name: 'medias',
  initialState,
  reducers: {
    onDefaultSelected: (state, action) => {
      const {data} = action.payload

      const updatedSelected = [...state.selected]
      data.forEach((media: any) => {
        updatedSelected.push(media)
      })
      state.selected = updatedSelected
    },
    onSelect: (state, action) => {
      const {id, conversions} = action.payload
      const newItem = {
        id,
        conversions,
      }
      const isExist = state.selected.findIndex((e: any) => e.id === id)
      if (isExist > -1) {
        const selectedItems = state.selected.filter((item: any) => item.id !== id)
        state.selected = selectedItems
      } else {
        const updatedSelected = [...state.selected]
        updatedSelected.push(newItem)
        state.selected = updatedSelected
      }
    },
    onRemoveSelected: (state, action) => {
      const index = action.payload
      const newSelected = [...state.selected]

      newSelected.splice(index, 1)

      state.selected = newSelected
    },
    onClear: (state) => {
      state.selected = []
    },
    setShowMediaModal: (state, action) => {
      const {show} = action.payload
      state.showMediaModal = show
    },
    setSelectedMedia: (state, action) => {
      const {data} = action.payload
      const newData = [
        data.id,
        {
          thumb: data.conversions.thumb.url,
          original: data.conversions.original.url,
          medium: data.conversions.medium.url,
          large: data.conversions.large.url,
        },
      ]
      const array = state.selected
      array.push(newData)
      state.selected = array
    },
  },
})

export default mediaSlice.reducer

export const {
  setSelectedMedia,
  setShowMediaModal,
  onSelect,
  onDefaultSelected,
  onClear,
  onRemoveSelected,
} = mediaSlice.actions

export const selectShowMediaModal = (state: IMediaObject) => state.media.showMediaModal
export const selectedMedias = (state: IMediaObject) => state.media.selected
