import {apiSlice} from 'app/api/apiSlice'

const DISPLAY_NAME = 'media'
const API_ROUTE = '/panel/media'

export const mediaApiSlice = apiSlice.injectEndpoints({
  tagTypes: [DISPLAY_NAME],
  endpoints: (builder: any) => ({
    getMedia: builder.query({
      query: (page: string) => {
        return {
          url: API_ROUTE,
          params: {
            page,
            per_page: 33,
          },
        }
      },
      providesTags: [DISPLAY_NAME],
    }),
    uploadMedia: builder.mutation({
      query: (body: any) => ({
        url: '/panel/media/public',
        method: 'POST',
        body,
      }),
      invalidatesTags: [DISPLAY_NAME],
    }),
  }),
})

export const {useGetMediaQuery, useUploadMediaMutation} = mediaApiSlice
