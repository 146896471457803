import './FileUpload.scss'

type Props = {
  name: string
  id: string
  conversions: {medium: {url: string}}
  type: string
  onRemoveFile: any
}

const FileUploadItem = ({conversions, name, type, onRemoveFile, id}: Props) => {
  return (
    <div className='text-truncate'>
      <div className='d-flex align-items-center gap-5'>
        {/* trashIcon */}
        <i
          className='fa-solid fa-trash text-secondary cursor-pointer fs-4'
          onClick={() => onRemoveFile(id)}
        />
        {/* name and icon */}
        <div className='d-flex align-items-center gap-2'>
          {type === 'image' ? (
            <i className='fa-solid fa-image text-secondary'></i>
          ) : (
            <i className='fa-solid fa-video text-secondary'></i>
          )}
          <strong className='text-truncate w-75'>{name}</strong>
        </div>
      </div>

      <div className='h-100 mt-2 text-center' style={{minHeight: 100}}>
        <img src={conversions.medium.url} alt={conversions.medium.url} className='w-100 h-100' />
      </div>
    </div>
  )
}

export default FileUploadItem
